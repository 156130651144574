<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>
          <router-link text class="myButton title" :to="to"
            ><v-icon class="icon mr-5"> mdi-arrow-left </v-icon>{{ title }}</router-link
          >
        </h1>
      </v-col>
      <v-col class="flex" v-if="hasRightSlot">
        <slot name="right"></slot>
      </v-col>
    </v-row>
    <slot />
  </v-container>
</template>
<script>
export default {
  name: 'TitleLink',
  props: ['title', 'to'],
  computed: {
    hasRightSlot() {
      return !!this.$slots.right;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/_variables.scss';
.myButton {
  text-decoration: none;
  color: black;
  display: flex;
  transition: all 0.1s ease-in;

  i {
    color: black;
  }
  &:hover,
  &:hover > i {
    color: $primary;
  }
}
</style>
