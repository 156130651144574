<template>
  <title-link-wrapper title="Wróć do listy użytkowników" :to="{ name: 'EmployeesList' }">
    <v-container class="white pa-8 mt-8">
      <v-row class="mb-6">
        <v-col>
          <h2 class="text font-weight-regular">Szczegóły użytkownika</h2>
        </v-col>
      </v-row>
      <v-form v-model="isFormValid" ref="form">
        <v-row>
          <v-col class="pt-1" cols="12" md="6">
            <h2 class="text mb-3">Imię*</h2>
            <v-text-field
              v-model="employee.firstName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-1" cols="12" md="6">
            <h2 class="text mb-3">Nazwisko*</h2>
            <v-text-field
              v-model="employee.lastName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Numer telefonu*</h2>
            <v-text-field
              v-model="employee.phone"
              :rules="validator.phoneRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">E-mail*</h2>
            <v-text-field
              v-model="employee.email"
              :rules="validator.emailRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <h2 class="text mb-3">Rola*</h2>
            <v-select
              :items="roles"
              v-model="employee.role"
              :rules="validator.requiredSelectRules"
              clearable
              outlined
              solo
              class="right-input"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="isTraderOrFitter">
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Nazwa firmy*</h2>
            <v-text-field
              v-model="employee.companyName"
              :rules="validator.shortDescRequiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">NIP*</h2>
            <v-text-field
              v-model="employee.NIP"
              :rules="validator.nipRequiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="isTraderOrFitter"><v-divider class="mb-6"></v-divider></v-row>
        <v-row v-if="isTraderOrFitter">
          <v-col>
            <h2 class="text mb-3">Umowa współpracy*</h2>
          </v-col>
        </v-row>
        <v-row v-if="isTraderOrFitter" class="pb-5">
          <v-col>
            <outlined-button outlined class="text-none" :loading="isSelecting" :onClick="addFile">
              <v-icon left> mdi-plus-circle-outline </v-icon>
              {{ buttonName }}
            </outlined-button>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onFileChanged"
            />
          </v-col>
          <v-col>
            <v-col v-if="employee.file.name">
              <v-icon> mdi-file-document-outline </v-icon>
              {{ employee.file.name }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col v-if="isTraderOrFitter" class="text-sm">
            Przesłana umowa powinna mieć formę skanu zapisaną w pliku .pdf o wadze max 2mb.
          </v-col>
        </v-row>
        <v-row><v-divider class="mb-6"></v-divider></v-row>
        <v-row class="pb-5">
          <v-col cols="4">
            <outlined-button :onClick="addEmployee" :loading="loading" outlined
              >Dodaj użytkownika</outlined-button
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import OutlinedButton from '../../components/buttons/OutlinedButton.vue';
import TitleLinkWrapper from '../../components/TitleLinkWrapper.vue';
import { validators, cooperationAgreement, checkFileSize } from '../../validators/Validators';
import { objRoles } from '../../static/roles';
import UserService from '../../services/UserService';

export default {
  name: 'AddEmployee',
  data() {
    return {
      employee: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        companyName: '',
        NIP: '',
        role: null,
        file: {},
      },
      isFormValid: true,
      loading: false,
      isSelecting: false,
      roles: Object.values(objRoles),
      objRoles,
      isFileValid: false,
    };
  },
  components: { TitleLinkWrapper, OutlinedButton },
  methods: {
    async init() {
      this.isFormValid = true;
      this.isSelecting = false;
    },
    addFile() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      if (file.name) {
        if (!cooperationAgreement(file.name)) {
          this.$toasted.global.error({
            message: 'Dodany plik ma niewłaściwy format.',
          });
          return;
        }
        if (!checkFileSize(file.size)) {
          this.$toasted.global.error({
            message: 'Dodany plik jest za duży.',
          });
          return;
        }
        this.employee.file = file;
        this.$toasted.global.success({
          message: 'Plik został poprawnie dodany.',
        });
        this.isFileValid = true;
      } else {
        this.$toasted.global.error({ message: 'Wybierz plik' });
      }
    },
    notOffice() {
      return this.employee.role !== this.objRoles.OFFICE.value;
    },
    async addEmployee() {
      this.$refs.form.validate();

      if (this.notOffice() && !this.isFileValid) {
        this.$toasted.global.error({
          message: 'Dodanie umowy współpracy jest obowiązkowe.',
        });
        return;
      }
      if (this.isFormValid) {
        try {
          this.loading = true;
          const addedUser = await UserService.createUser(this.employee);

          if (this.notOffice()) {
            await this.$store.dispatch('user/uploadFile', {
              userId: addedUser.id,
              type: this.employee.file.type,
              file: this.employee.file,
            });
          }

          this.$router.push({ name: 'EmployeesList' });
          this.$toasted.global.success({
            message: 'Użytkownik został poprawnie dodany.',
          });
          this.clearEmployee();
          this.$refs.form.reset();
        } catch (error) {
          this.$toasted.global.error({
            message: 'Nie udało się dodać użytkownika. Spróbuj później.',
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
      } else {
        this.$toasted.global.error({
          message: 'Formularz zawiera błędy.',
        });
      }
    },
    clearEmployee() {
      this.employee = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        companyName: '',
        NIP: '',
        role: '',
        file: {},
      };
      this.isFormValid = false;
      this.isFileValid = false;
    },
  },
  computed: {
    validator() {
      return validators;
    },
    buttonName() {
      return this.employee.file.name ? 'Nadpisz plik' : 'Dodaj plik';
    },
    isTraderOrFitter() {
      return (
        this.employee.role === this.objRoles.TRADER.value ||
        this.employee.role === this.objRoles.FITTER.value
      );
    },
  },
  async activated() {
    this.init();
  },
};
</script>
<style lang="scss" scoped></style>
