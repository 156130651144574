<template>
  <v-btn
    elevation="0"
    @click="onclick"
    :loading="loading"
    :color="color ? color : 'primary'"
    class="white--text pa-4 outlinedBtn"
    :to="to ? to : ''"
    x-large
    outlined
    :class="childclass"
    ><slot
  /></v-btn>
</template>
<script>
export default {
  name: 'OutlinedButton',
  props: ['onClick', 'loading', 'color', 'to', 'childclass'],
  computed: {
    onclick() {
      if (this.onClick) {
        return this.onClick;
      } else {
        return () => {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../scss/_variables.scss';

.outlinedBtn {
  border: 2px solid $primary;
}
</style>
